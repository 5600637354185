<template>
    <div class="tw-max-w-full">
        <div :class="fullScreen ? 'tw-fixed tw-w-screen tw-inset-0 tw-z-20' : 'tw-relative tw-rounded-md'" :style="!fullScreen ? 'max-height: ' + maxHeight : ''" class="tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-flex tw-flex-col tw-bg-white tw-text-gray-700 tw-min-w-min tw-select-none">
            <toolbar :view="view" :searchQuery="searchQuery" :getItems="getItems" :selectedItem="selectedItem" :contextItem="contextItem" :loadingState="loadingState" :readOnly="readOnly" />
            <breadcrumb :loadingState="loadingState" :breadcrumb="breadcrumb" :searchMode="searchMode" :searchQuery="searchQuery" :isGoUpAvailable="isGoUpAvailable" />
            <explorer :view="view" :searchQuery="searchQuery" :sort="sort" :fullScreen="fullScreen" :directoryContent="directoryContent" :getPath="getPath" :getItems="getItems" :selectedItem="selectedItem" :loadingState="loadingState" :searchMode="searchMode" :readOnly="readOnly" />
            <statusbar :directoryCount="directoryCount" />
        </div>
        <vue-simple-context-menu element-id="context-menu-1" :options="contextOptions" ref="simpleContextMenu" @option-clicked="optionClicked" v-if="!readOnly" />
    </div>
</template>

<script>
import axios, {CancelToken} from "@/axios";
import {EventBus} from "@/main";
import {v4 as uuidv4} from "uuid";
import {DateTime} from "luxon";
import {cloneDeep} from "lodash";

import Toolbar from "../components/Toolbar.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import Explorer from "../components/Explorer.vue";
import Statusbar from "../components/Statusbar.vue";
let cancelFetch;

export default {
    name: "FileExplorer",
    data() {
        return {
            directoryContent: [],
            fullScreen: false,
            view: "grid",
            searchQuery: "",
            sort: {active: false, column: "", order: ""},
            currentDirectory: "_ROOT",
            breadcrumb: [{_id: "_ROOT", name: ""}],
            searchMode: false,
            selectedItem: null,
            contextOptions: [],
            contextItem: null,
            loadingState: {
                uploading: false,
                fetching: false,
            },
            maxFileMB: 100,
            filesUploadQueue: [],
        };
    },
    created() {
        EventBus.$on("fetchDirectoryContents", async () => {
            await this.fetchDirectoryContents();
        });
        EventBus.$on("onViewChange", (view) => {
            this.view = view;
        });
        EventBus.$on("onOpenItem", async (item) => {
            await this.onOpenItem(item);
        });
        EventBus.$on("onUploadFiles", async (files) => {
            await this.onUploadFiles(files);
        });
        EventBus.$on("onBreadcrumb", async (id) => {
            await this.onBreadcrumb(id);
        });
        EventBus.$on("onSearchMode", (val) => {
            if (!val) {
                this.searchQuery = "";
            }
            this.searchMode = val;
        });
        EventBus.$on("onSearchInput", (val) => {
            this.onSearchInput(val);
        });
        EventBus.$on("onSelectItem", (item) => {
            this.selectedItem = item._id;
        });
        EventBus.$on("onContextMenu", (data) => {
            this.onContextMenu(data.event, data.options, data.item);
        });
        EventBus.$on("onDirDelete", async (item) => {
            await this.onDirDelete(item);
        });
        EventBus.$on("onRenameDir", async (item) => {
            await this.onRenameDir(item);
        });
        EventBus.$on("onCreateDir", async (data) => {
            await this.onCreateDir(data);
        });
        EventBus.$on("onLoadingStateChange", ({key, val}) => {
            this.loadingState[key] = val;
        });
        EventBus.$on("uploadFiles", async (data) => {
            this.uploadFiles(data);
        });
    },
    beforeDestroy() {
        EventBus.$off("fetchDirectoryContents");
        EventBus.$off("onViewChange");
        EventBus.$off("onOpenItem");
        EventBus.$off("onUploadFiles");
        EventBus.$off("onBreadcrumb");
        EventBus.$off("onSearchMode");
        EventBus.$off("onSelectItem");
        EventBus.$off("onContextMenu");
        EventBus.$off("onDirDelete");
        EventBus.$off("onRenameDir");
        EventBus.$off("onCreateDir");
        EventBus.$off("onLoadingStateChange");
        EventBus.$off("uploadFiles");
    },
    async mounted() {
        await this.fetchDirectoryContents();
    },
    computed: {
        getDocType() {
            return this.title.split(" ").join("__");
        },
        getPath() {
            return this.breadcrumb;
        },
        getItems() {
            const content = this.directoryContent.map((content) => {
                let json = {};
                json._id = content._id;
                if (content.node_type == "directory") {
                    json.type = "dir";
                    json.basename = content.folder_name;
                } else {
                    json.type = "file";
                    json.basename = content.file_name;
                }
                let gen_path = "";
                if (content.full_path) {
                    const vPath = content.full_path.filter((path) => path.name);
                    gen_path = vPath.map((path) => path.name).join("/");
                }
                json.gen_path = `/${gen_path}`;
                json.mime_type = content.mime_type;
                json.path = content?.full_path ?? [];
                json.last_modified = content?.updated_at ?? content.created_at;
                json.last_modified = DateTime.fromISO(json.last_modified).toFormat("FF");
                json.file_size = content?.file_size;
                return json;
            });
            return content;
        },
        directoryCount() {
            let fileCount = 0;
            let folderCount = 0;
            let files = this.getItems.filter((item) => item.type === "file");
            let folders = this.getItems.filter((item) => item.type === "dir");
            if (files.length > 0) {
                fileCount = files.length;
            }
            if (folders.length > 0) {
                folderCount = folders.length;
            }
            return {files: fileCount, folders: folderCount};
        },
        isGoUpAvailable() {
            return this.breadcrumb.length > 1;
        },
    },
    props: {
        maxHeight: {
            type: String,
            default: "520px",
        },
        title: {
            type: String,
            require: true,
        },
        key_name: {
            type: String,
            require: true,
        },
        key_value: {
            type: String,
            require: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Toolbar,
        Explorer,
        Statusbar,
        Breadcrumb,
    },
    methods: {
        fetchAbort() {
            if (cancelFetch) {
                cancelFetch("cancelled req");
            }
            this.loadingState.fetching = false;
        },
        async fetchDirectoryContents() {
            this.fetchAbort();
            this.loadingState.fetching = true;
            this.resetOps();
            try {
                let {data} = await axios.get(`/docs/directory-contents?${this.key_name}=${this.key_value}&directory_id=${this.currentDirectory}&doc_type=${this.getDocType}`, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelFetch = c;
                    }),
                });
                this.directoryContent = data.directory_contents;
                this.loadingState.fetching = false;
            } catch (error) {
                this.loadingState.fetching = error.message == "cancelled req";
                console.log(error);
            }
        },
        async onOpenItem(item) {
            this.selectedItem = null;
            if (item.type === "file") {
                let url = await this.downloadFile(item);
                window.open(url, "_blank");
            } else {
                this.currentDirectory = item._id;
                this.breadcrumb.push({_id: item._id, name: item.basename});
                await this.fetchDirectoryContents();
            }
        },
        async downloadFile(fileObject) {
            let fileId = fileObject._id;
            try {
                const {data} = await axios.get(`/docs/signed-url?file_id=${fileId}`);
                return data.url;
            } catch (error) {
                console.log(error);
            }
        },
        dupsCheck(files) {
            let folderNames = [];
            let fileNames = [];
            files.forEach((file) => {
                const path = file.path.split("/")[0];
                if (path && file.name.trim() !== path.trim()) {
                    let folderCheck = this.getItems.some((ele) => ele.type === "dir" && ele.basename === path);
                    if (folderCheck && !folderNames.includes(path)) {
                        folderNames.push(path);
                    }
                }
                if (file.name == file.path) {
                    const fileCheck = this.getItems.some((ele) => ele.type === "file" && ele.basename === file.name);
                    if (fileCheck && !fileNames.includes(path)) {
                        fileNames.push(file.name);
                    }
                }
            });
            if (folderNames.length > 0 || fileNames.length > 0) {
                this.$modal.show("modal-replace", {folderNames, fileNames, files});
                return false;
            }
            return true;
        },
        async onUploadFiles(files) {
            if (files.length <= 0) {
                this.$toast.error("Cannot upload empty folder");
                return;
            }
            if (this.searchMode || this.loadingState.uploading || this.loadingState.fetching) {
                this.$toast.error("Cannot upload files/folders while some operation is going on!");
                return;
            }
            const valid = this.filesSizeCheck(files);
            if (valid) {
                let dupCheck = this.dupsCheck(files);
                if (dupCheck) {
                    await this.uploadFiles(files);
                }
            }
        },
        async uploadFiles(files) {
            this.$modal.hide("modal-replace");
            this.loadingState.uploading = true;
            let session_id = uuidv4();
            console.log(files, "FILES");
            this.filesUploadQueue = cloneDeep(this.files);
            for (const file of files) {
                // console.log(index, "INDEX");
                let formData = new FormData();
                formData.append("file", file);
                formData.append("path", file.path);
                formData.append("filename", file.name);
                formData.append("full_path", JSON.stringify(this.breadcrumb));
                formData.append("size", file.size);
                formData.append(this.key_name, this.key_value);
                formData.append("folder_id", this.currentDirectory);
                formData.append("doc_type", this.getDocType);
                formData.append("session_id", session_id);
                try {
                    const {data} = await axios.post(`/docs/directories`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    console.log(data, "DATA");
                } catch (error) {
                    console.log(error);
                }
            }
            await this.fetchDirectoryContents();
            this.loadingState.uploading = false;
        },
        async onBreadcrumb(id) {
            if (this.currentDirectory != id) {
                const breadcrumbIndex = this.breadcrumb.findIndex((bread) => bread._id === id);
                if (breadcrumbIndex != -1) this.breadcrumb = this.breadcrumb.slice(0, breadcrumbIndex + 1);
                this.currentDirectory = id;
                await this.fetchDirectoryContents();
            }
        },
        async onSearchInput(val) {
            this.fetchAbort();
            this.loadingState.fetching = true;
            this.searchQuery = val;
            this.directoryContent = [];
            try {
                const {data} = await axios.get(`/docs/search?${this.key_name}=${this.key_value}&doc_type=${this.getDocType}&search_key=${this.searchQuery}`, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelFetch = c;
                    }),
                });
                this.directoryContent = data.matches;
                this.loadingState.fetching = false;
            } catch (error) {
                this.loadingState.fetching = error.message == "cancelled req";
            }
        },
        onContextMenu(event, options, item) {
            this.contextOptions = options;
            this.contextItem = item;
            this.$refs.simpleContextMenu.showMenu(event, options);
        },
        async optionClicked({option}) {
            switch (option.name) {
                case "Refresh":
                    await this.fetchDirectoryContents();
                    break;
                case "Open":
                    this.onOpenItem(this.contextItem);
                    break;
                case "Delete":
                    this.$modal.show("modal-delete", {getItems: this.getItems, item: this.contextItem, selectedItem: this.selectedItem});
                    break;
                case "Rename":
                    this.$modal.show("modal-rename", {getItems: this.getItems, item: this.contextItem, selectedItem: this.selectedItem});
                    break;
                case "New Folder":
                    this.$modal.show("modal-create-folder");
                    break;

                default:
                    break;
            }
        },
        async onDirDelete(item) {
            try {
                const objectType = item.type === "dir" ? "directory" : "file";
                this.loadingState.fetching = true;
                this.$modal.hide("modal-delete");
                const doc = {
                    object_type: objectType,
                    object_id: item._id,
                    [this.key_name]: this.key_value,
                    full_path: item.path,
                };
                await axios.post(`/docs/delete/directories`, doc);
                await this.fetchDirectoryContents();
            } catch (error) {
                console.log(error);
            }
        },
        async onRenameDir(item) {
            const objectType = item.type === "dir" ? "directory" : "file";
            try {
                this.resetOps();
                const doc = {
                    object_type: objectType,
                    object_id: item._id,
                    new_object_name: item.basename,
                    [this.key_name]: this.key_value,
                };
                this.loadingState.fetching = true;
                this.$modal.hide("modal-rename");
                await axios.post("/docs/rename", doc);
                await this.fetchDirectoryContents();
            } catch (error) {
                console.log(error);
            }
        },
        resetOps() {
            this.selectedItem = null;
            this.contextItem = null;
        },
        async onCreateDir(data) {
            const isFolderExist = this.getItems.some((item) => item.type === "dir" && item.basename === data.name);
            if (isFolderExist) {
                this.$toast.error(`This destination already contains a folder named '${data.name}'`);
            } else {
                this.$modal.hide("modal-create-folder");
                await this.createDir(data);
            }
        },
        async createDir(data) {
            try {
                this.loadingState.fetching = true;
                const doc = {
                    folder_name: data.name,
                    [this.key_name]: this.key_value,
                    folder_type: this.getDocType,
                    parent_id: this.currentDirectory,
                    full_path: this.breadcrumb,
                };
                await axios.post("/docs/folders", doc);
                this.loadingState.fetching = false;
                await this.fetchDirectoryContents();
            } catch (error) {
                console.log(error);
            }
        },
        filesSizeCheck(files) {
            const fileSize = [...files].reduce((acc, curr) => acc + curr.size, 0);
            const size = fileSize / 1000000;
            if (this.maxFileMB >= size) {
                return true;
            } else {
                this.$toast.error(`The selected/dragged files exceeds the maximum file size. You cannot upload files greater than ${this.maxFileMB}mb`);
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-simple-context-menu {
    position: fixed !important;
}
</style>
