<template>
    <div class="tw-p-1 tw-text-xs tw-bg-brand-area-color tw-border-0 tw-border-t tw-border-gray-300 tw-flex tw-justify-between tw-items-center tw-select-none">
        <div class="tw-flex tw-leading-5 tw-items-center">
            <div>{{ directoryCount.folders }} Folders, {{ directoryCount.files }} files</div>
        </div>
        <!-- <div class="tw-flex tw-leading-5 tw-items-center">
            <span class="tw-mr-1" aria-label="About" data-microtip-position="top-left" role="tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-stroke-slate-500 tw-cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
            </span>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "Statusbar",
    props: {
        directoryCount: {
            type: Object,
            default: () => ({files: 0, folders: 0}),
        },
    },
};
</script>

<style lang="scss" scoped></style>
