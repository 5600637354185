<template>
    <div class="tw-flex tw-p-1.5 tw-bg-brand-area-color tw-border-0 tw-border-b tw-border-gray-300 tw-items-center tw-select-none tw-text-xs">
        <span aria-label="Go up a directory" data-microtip-position="bottom-right" role="tooltip" content="Go up a directory" v-tippy @click="onGoUpDir()">
            <svg class="tw-h-6 tw-w-6 tw-p-0.5 tw-rounded" :class="isGoUpAvailable ? 'tw-text-gray-700 hover:tw-bg-gray-300 tw-cursor-pointer' : 'tw-text-gray-400'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
            </svg>
        </span>
        <span aria-label="Refresh" data-microtip-position="bottom-right" role="tooltip" content="Refresh" v-tippy>
            <svg @click="onRefresh()" class="tw-h-6 tw-w-6 tw-p-1 tw-rounded tw-text-gray-700 hover:tw-bg-gray-300 tw-cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="-40 -40 580 580" fill="currentColor">
                <path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"></path>
            </svg>
        </span>
        <div v-if="!searchMode" class="tw-group tw-flex tw-bg-white tw-items-center tw-rounded tw-p-1 tw-ml-2 tw-w-full" @click.self="onSearchMode(true)">
            <svg @click="onBreadcrumb('_ROOT')" class="tw-h-6 tw-w-6 tw-p-1 tw-rounded tw-text-gray-700 hover:tw-bg-gray-300 tw-cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            <div class="tw-flex tw-leading-5">
                <div v-for="(item, index) in breadcrumb" :key="index">
                    <template v-if="item.name">
                        <span class="tw-text-neutral-300 tw-mx-0.5">/</span>
                        <span class="tw-px-1.5 tw-py-1 tw-text-slate-700 hover:tw-bg-neutral-100 tw-rounded tw-cursor-pointer" :title="item.basename" @click="onBreadcrumb(item._id)">{{ item.name }}</span>
                    </template>
                </div>
            </div>
            <svg class="tw-animate-spin tw-p-1 tw-h-6 tw-w-6 tw-text-gray-400 tw-ml-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loadingState.fetching">
                <circle class="tw-opacity-25 tw-stroke-blue-900" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        <div v-else class="tw-relative tw-flex tw-bg-white tw-items-center tw-rounded tw-p-1 tw-ml-2 tw-w-full">
            <svg class="searchicon tw-h-6 tw-w-6 tw-p-1 tw-m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
            </svg>
            <div class="tw-w-full"></div>
            <input ref="searchInput" @keydown.esc="onSearchMode(false)" @blur="handleBlur" :value="searchQuery" @keyup="onSearchInput" placeholder="Search anything.." class="tw-absolute tw-ml-6 tw-pt-1 tw-pb-0 tw-px-2 tw-border-0 tw-ring-0 tw-outline-none tw-text-gray-600 focus:tw-ring-transparent focus:tw-border-transparent tw-bg-transparent" type="text" />
            <svg class="tw-w-6 tw-h-6 tw-cursor-pointer" @click="onSearchMode(false)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </div>
    </div>
</template>

<script>
import {EventBus} from "@/main.js";
import {debounce} from "lodash";

export default {
    name: "ToolBar",
    computed: {},
    created() {
        this.onSearchInput = debounce(this.onSearchInput, 400, {
            leading: false,
            trailing: true,
        });
    },
    methods: {
        onRefresh() {
            EventBus.$emit("fetchDirectoryContents");
        },
        onBreadcrumb(id) {
            EventBus.$emit("onBreadcrumb", id);
        },
        onSearchMode(val) {
            EventBus.$emit("onSearchMode", val);
            if (val) {
                this.$nextTick(() => {
                    this.$refs.searchInput?.focus();
                });
            } else {
                EventBus.$emit("onSearchInput", "");
                EventBus.$emit("fetchDirectoryContents");
            }
        },
        handleBlur() {
            if (this.searchQuery == "") {
                EventBus.$emit("onSearchMode", false);
                this.onRefresh();
            }
        },
        onSearchInput({target}) {
            console.log("stopped typing");
            const value = target.value.trim();
            EventBus.$emit("onSearchInput", value);
            if (!value) {
                EventBus.$emit("fetchDirectoryContents");
            }
        },
        onGoUpDir() {
            if (this.isGoUpAvailable) {
                const lenBreadcrumb = this.breadcrumb.length;
                const breadcrumb = this.breadcrumb[lenBreadcrumb - 2];
                this.onBreadcrumb(breadcrumb._id);
            }
        },
    },
    props: {
        loadingState: {
            type: Object,
            default: () => {},
        },
        breadcrumb: {
            type: Array,
            default: () => [],
        },
        searchMode: {
            type: Boolean,
            default: false,
        },
        searchQuery: {
            type: String,
            default: "",
        },
        isGoUpAvailable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    stroke: #6b7280;
    &:hover {
        stroke: #0e7490;
    }
}
.disabled-icon {
    stroke: #e5e7eb;
}
.searchicon {
    fill: rgb(243, 244, 246);
    stroke: rgb(156, 163, 175);
}
</style>
