<template>
    <modal name="modal-rename" height="auto" @before-open="onBeforeOpen">
        <div class="tw-relative tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-max-w-4xl md:tw-max-w-2xl lg:tw-max-w-3xl xl:tw-max-w-5xl tw-w-full">
            <div class="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
                <div class="sm:tw-flex sm:tw-items-start">
                    <div class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-blue-50 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 rename-icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                        </svg>
                    </div>
                    <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left tw-w-full">
                        <h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900" id="modal-title">
                            Rename
                        </h3>
                        <div class="tw-mt-2">
                            <p class="tw-flex tw-text-sm tw-text-gray-800 tw-py-2">
                                <svg v-if="item.type == 'dir'" xmlns="http://www.w3.org/2000/svg" class="folder-icon tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                                </svg>
                                <span class="tw-ml-1.5 tw-break-all">{{ basename }}</span>
                            </p>
                            <input class="tw-px-2 tw-py-2 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-w-full focus:tw-border-brand focus:tw-outline-none" placeholder="Name" type="text" v-model="item.basename" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                <button @click="onRenameDir()" type="button" class="tw-cursor-pointer tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                    Rename
                </button>
                <button @click="onClose()" type="button" class="tw-cursor-pointer tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                    Cancel
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import {EventBus} from "@/main";
import {cloneDeep} from "lodash";

export default {
    name: "modal-rename",
    data() {
        return {
            item: {},
            basename: "",
            items: [],
        };
    },
    methods: {
        onBeforeOpen({params}) {
            this.items = cloneDeep(params.getItems);
            if (params.item) {
                this.item = cloneDeep(params.item);
            } else {
                let item = cloneDeep(params.getItems).find((item) => item._id === params.selectedItem);
                this.item = item;
            }
            this.basename = this.item.basename;
        },
        onClose() {
            this.$modal.hide("modal-rename");
        },
        onRenameDir() {
            if (this.basename === this.item.basename) {
                this.onClose();
                return;
            }
            const isExist = this.items.some((elem) => elem.type === this.item.type && elem.basename === this.item.basename);
            if (isExist) {
                this.$toast.error("Folder with this name already exist");
                return;
            }
            if (this.item.basename) {
                EventBus.$emit("onRenameDir", this.item);
            } else {
                this.$toast.error("Folder name can't be empty");
            }
        },
    },
};
</script>

<style style="scss" scoped>
.folder-icon {
    stroke: #0ea5e9;
    fill: #0ea5e9;
}
.rename-icon {
    stroke: #2563eb;
}
</style>
