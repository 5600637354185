<template>
    <modal name="modal-create-folder" height="auto" @before-open="onBeforeOpen">
        <div class="tw-relative tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-max-w-4xl md:tw-max-w-2xl lg:tw-max-w-3xl xl:tw-max-w-5xl tw-w-full">
            <div class="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
                <div class="sm:tw-flex sm:tw-items-start">
                    <div class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-blue-50 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                        </svg>
                    </div>
                    <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left tw-w-full">
                        <h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900" id="modal-title">
                            New Folder
                        </h3>
                        <div class="tw-mt-2">
                            <label for="create-folder" class="tw-text-sm tw-text-gray-500">Create a new folder</label>
                            <input id="create-folder" class="tw-px-2 tw-py-2 tw-mt-2 tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-w-full focus:tw-border-brand focus:tw-outline-none" placeholder="Folder Name" type="text" v-model.trim="folderName" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                <button @click="onCreateDir()" type="button" class="tw-cursor-pointer tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-blue-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-blue-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                    Create
                </button>
                <button @click="onClose()" type="button" class="tw-cursor-pointer tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                    Cancel
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import {EventBus} from "@/main";

export default {
    name: "modal-new-folder",
    data() {
        return {
            folderName: "",
        };
    },
    methods: {
        onCreateDir() {
            if (this.folderName) {
                EventBus.$emit("onCreateDir", {name: this.folderName});
            } else {
                this.$toast.error("Please enter new folder name");
            }
        },
        onClose() {
            this.$modal.hide("modal-create-folder");
        },
        onBeforeOpen() {
            this.folderName = "";
        },
    },
};
</script>

<style style="scss" scoped>
.icon {
    stroke: #2563eb;
}
</style>
