<template>
    <div class="tw-border-0 tw-border-b tw-border-gray-300 tw-flex tw-justify-between tw-items-center tw-py-1 tw-text-sm">
        <div class="tw-flex tw-text-center tw-items-center" v-if="!searchQuery.length">
            <template v-if="!readOnly">
                <div class="tw-mx-1.5" aria-label="New Folder" data-microtip-position="bottom-right" role="tooltip" @click="showModal('modal-create-folder')" content="New Folder" v-tippy>
                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"></path>
                    </svg>
                </div>
                <!-- <div class="tw-mx-1.5" aria-label="New File" data-microtip-position="bottom" role="tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"></path>
                </svg>
                </div> -->
                <div class="tw-mx-1.5" aria-label="Rename" data-microtip-position="bottom" role="tooltip" content="Rename" @click="selectedItem && showModal('modal-rename')" v-tippy>
                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto" :class="selectedItem ? 'tw-cursor-pointer icon' : 'disabled-icon'" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path>
                    </svg>
                </div>
                <div class="tw-mx-1.5" aria-label="Delete" data-microtip-position="bottom" role="tooltip" content="Delete" @click="selectedItem && showModal('modal-delete')" v-tippy>
                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto" :class="selectedItem ? 'tw-cursor-pointer icon' : 'disabled-icon'" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path>
                    </svg>
                </div>
                <!-- <div class="tw-mx-1.5" aria-label="Upload" data-microtip-position="bottom" role="tooltip" @click="showModal('modal-upload')" content="Upload" v-tippy>
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"></path></svg>
                </div> -->
                <div class="tw-mx-1.5" aria-label="Upload" data-microtip-position="bottom" role="tooltip" content="Upload File" v-tippy>
                    <label for="upload-file" @click="resetImageUploader('imageUploaderfile')">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5"><path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"></path></svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer icon" viewBox="0 0 24 24" stroke-width="1">
                            <path
                                d="M20,6.52897986 L20,19.5010024 C20,20.8817143 18.8807119,22.0010024 17.5,22.0010024 L6.5,22.0010024 C5.11928813,22.0010024 4,20.8817143 4,19.5010024 L4,4.50100238 C4,3.1202905 5.11928813,2.00100238 6.5,2.00100238 L15.4720225,2.00100238 C15.6047688,1.99258291 15.7429463,2.03684187 15.8535534,2.14744899 L19.8535534,6.14744899 C19.9641605,6.25805611 20.0084195,6.39623363 20,6.52897986 Z M15,3.00100238 L6.5,3.00100238 C5.67157288,3.00100238 5,3.67257525 5,4.50100238 L5,19.5010024 C5,20.3294295 5.67157288,21.0010024 6.5,21.0010024 L17.5,21.0010024 C18.3284271,21.0010024 19,20.3294295 19,19.5010024 L19,7.00100238 L15.5,7.00100238 C15.2238576,7.00100238 15,6.77714475 15,6.50100238 L15,3.00100238 Z M16,3.70810916 L16,6.00100238 L18.2928932,6.00100238 L16,3.70810916 Z M12,10.7071068 L12,17.5 C12,17.7761424 11.7761424,18 11.5,18 C11.2238576,18 11,17.7761424 11,17.5 L11,10.7071068 L8.85355339,12.8535534 C8.65829124,13.0488155 8.34170876,13.0488155 8.14644661,12.8535534 C7.95118446,12.6582912 7.95118446,12.3417088 8.14644661,12.1464466 L11.1464466,9.14644661 C11.3417088,8.95118446 11.6582912,8.95118446 11.8535534,9.14644661 L14.8535534,12.1464466 C15.0488155,12.3417088 15.0488155,12.6582912 14.8535534,12.8535534 C14.6582912,13.0488155 14.3417088,13.0488155 14.1464466,12.8535534 L12,10.7071068 Z"
                            />
                        </svg>
                        <input type="file" class="tw-hidden" id="upload-file" multiple @change="onUpload" ref="imageUploaderfile" />
                    </label>
                </div>
                <div class="tw-mx-1.5" aria-label="Upload" data-microtip-position="bottom" role="tooltip" content="Upload Folder" v-tippy>
                    <label for="upload-folder" @click="resetImageUploader('imageUploaderfolder')">
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"></path>
                        </svg>
                        <input ref="imageUploaderfolder" type="file" class="tw-hidden" id="upload-folder" webkitdirectory directory @change="onUpload" />
                    </label>
                </div>
            </template>
        </div>
        <div class="tw-flex tw-text-center" v-else>
            <div class="tw-pl-2">
                Search results for <span class="dark:tw-bg-gray-700 tw-bg-gray-200 tw-text-xs tw-px-2 tw-py-1 tw-rounded">{{ searchQuery }}</span>
            </div>
            <svg class="tw-animate-spin tw-p-0.5 tw-h-5 tw-w-5 tw-text-gray-700 tw-ml-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loadingState.fetching">
                <circle class="tw-opacity-25 tw-stroke-blue-900 dark:tw-stroke-blue-100" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="tw-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        <div class="tw-flex tw-text-center tw-items-center tw-justify-end">
            <div class="tw-mx-1.5" aria-label="View" data-microtip-position="bottom" role="tooltip" @click="onViewChange()" content="View" v-tippy>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon tw-h-6 tw-w-6 tw-m-auto tw-cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                    <path
                        v-if="view == 'grid'"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                    />
                    <path v-if="view == 'list'" stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import {EventBus} from "@/main.js";
import {fromEvent} from "file-selector";

export default {
    name: "toolbar",
    methods: {
        onViewChange() {
            let view = this.view === "grid" ? "list" : "grid";
            EventBus.$emit("onViewChange", view);
        },
        showModal(modalName) {
            this.$modal.show(modalName, {getItems: this.getItems, item: this.contextItem, selectedItem: this.selectedItem});
        },
        async onUpload(event) {
            const files = await fromEvent(event);
            console.log(files, "FILES");
            EventBus.$emit("onUploadFiles", files);
            event.target.value = "";
        },
        resetImageUploader(name) {
            this.$refs[name].value = "";
        },
    },
    props: {
        view: {
            type: String,
            default: "grid",
        },
        searchQuery: {
            type: String,
            default: "",
        },
        getItems: {
            type: Array,
            default: () => [],
        },
        selectedItem: {
            type: String,
            default: null,
        },
        contextItem: {
            type: Object,
            default: () => {},
        },
        loadingState: {
            type: Object,
            default: () => {},
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    stroke: #6b7280;
    &:hover {
        stroke: #0e7490;
    }
}
.disabled-icon {
    stroke: #e5e7eb;
}
</style>
