<template>
    <modal name="modal-upload" width="820px" height="auto" :clickToClose="false" @before-open="onBeforeOpen">
        <div class="tw-flex tw-flex-col tw-justify-between tw-min-h-full tw-h-full">
            <header class="tw-flex tw-items-center tw-justify-between tw-p-4">
                <div class="tw-flex tw-flex-col">
                    <span class="tw-font-semibold tw-text-gray-700 tw-text-lg tw-tracking-wide">Upload Files &amp; Folders</span>
                    <span class="tw-text-gray-400 tw-font-medium tw-text-sm tw-tracking-wide">Drag &amp; Drop</span>
                </div>
                <svg @click="onClose" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x tw-rounded-full tw-p-2 tw-text-gray-600 tw-cursor-pointer hover:tw-text-brand-hover">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </header>
            <div class="tw-container tw-flex-grow tw-overflow-y-auto scroll-bar tw-px-4 tw-py-6">
                <!-- <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
                    <div for="dropzone-file" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-36 tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-lg tw-cursor-pointer tw-bg-gray-50">
                        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-5 tw-pb-6">
                            <svg aria-hidden="true" class="tw-w-10 tw-h-10 tw-mb-3 tw-text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                            <p class="tw-mb-2 tw-text-sm tw-text-gray-500"><span class="tw-font-semibold">Drag and Drop</span></p>
                            <p class="tw-text-xs tw-text-gray-500">Files and Folders</p>
                        </div>
                        <input type="file" class="tw-hidden" id="dropzone-file" />
                    </div>
                </div> -->
                <file-pond name="file" credits="false" ref="pond" class-name="my-pond scroll-bar" label-idle="Drag &amp; Drop your folders &amp; files here..." allow-multiple="true" allowBrowse="false" maxFiles="10" />

                <div class="tw-py-6 tw-text-gray-400 tw-flex tw-w-full tw-items-center"><span class="tw-flex-grow tw-border-0 tw-border-solid tw-border-gray-200 tw-border-t"></span> <span class="tw-px-8"> OR </span><span class="tw-flex-grow tw-border-0 tw-border-solid tw-border-gray-200 tw-border-t"></span></div>

                <div class="tw-flex tw-items-center tw-justify-evenly tw-space-x-4">
                    <label id="upload-file" class="tw-border-none tw-rounded tw-text-sm tw-h-full tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-center tw-text-gray-800 hover:tw-text-white tw-bg-gray-100 tw-cursor-pointer hover:tw-bg-brand-hover tw-flex-grow">
                        <!-- <font-awesome-icon icon="file" class="tw-w-4 tw-mr-2" /> -->
                        <span> Click to Upload Files </span>
                        <input type="file" class="tw-hidden" id="upload-file" />
                    </label>
                    <label id="upload-folder" class="tw-border-none tw-rounded tw-text-sm tw-h-full tw-py-2 tw-px-4 tw-flex tw-items-center tw-justify-center tw-text-gray-800 hover:tw-text-white tw-bg-gray-100 tw-cursor-pointer hover:tw-bg-brand-hover tw-flex-grow">
                        <!-- <font-awesome-icon icon="folder" class="tw-w-4 tw-mr-2" /> -->
                        <span> Click to Upload Folders </span>
                        <input type="file" class="tw-hidden" id="upload-folder" webkitdirectory directory />
                    </label>
                </div>
            </div>
            <footer class="tw-flex tw-items-center tw-justify-end tw-p-4 tw-border-t tw-border-0 tw-border-solid tw-border-gray-200 tw-bg-brand-area-color">
                <button @click="onUpload" class="tw-border-none tw-rounded tw-text-sm tw-h-full tw-py-2 tw-px-4 tw-flex tw-items-center tw-text-white tw-bg-brand tw-cursor-pointer hover:tw-bg-brand-hover">
                    <font-awesome-icon icon="upload" class="tw-w-4 tw-mr-2" />
                    Upload
                </button>
            </footer>
        </div>
    </modal>
</template>

<script>
import {EventBus} from "@/main.js";

// Import FilePond
import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
    name: "modal-upload",
    components: {FilePond},
    data() {
        return {myFiles: [], getItems: []};
    },
    methods: {
        onClose() {
            this.$modal.hide("modal-upload");
        },
        onUpload() {
            let files = this.$refs.pond.getFiles().map((file) => file.file);
            this.myFiles = files;
            let folderName = "";
            files.forEach((file) => {
                if (file._relativePath) {
                    folderName = file._relativePath.split("/")[1];
                }
            });
            let isExist = this.getItems.some((item) => item.basename === folderName);
            if (isExist) {
                this.$modal.show("modal-replace", {folderName, files: this.myFiles});
            } else {
                EventBus.$emit("onUploadFiles", this.myFiles);
            }
        },
        onBeforeOpen({params}) {
            this.getItems = params.getItems;
        },
    },
};
</script>

<style style="scss" scoped>
::v-deep .my-pond {
    max-height: 20rem !important;
    overflow: auto !important;
}
</style>
