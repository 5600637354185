<template>
    <div>
        <file-explorer :title="title" :key_name="key_name" :key_value="key_value" :key="title" :readOnly="readOnly" />
        <component v-for="comp in fileModals" :is="comp.name" :key="comp.id" />
    </div>
</template>

<script>
import FileExplorer from "./components/FileExplorer.vue";
import * as Modals from "./modals.js";

export default {
    name: "file-browser",
    components: {
        FileExplorer,
        ...Modals,
    },
    props: {
        title: {
            type: String,
            require: true,
        },
        key_value: {
            type: String,
            require: true,
        },
        key_name: {
            type: String,
            require: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fileModals: [
                {name: "ModalUpload", id: 1},
                {name: "ModalReplace", id: 2},
                {name: "ModalDelete", id: 3},
                {name: "ModalRename", id: 4},
                {name: "ModalNewFolder", id: 5},
            ],
        };
    },
};
</script>

<style lang="scss" scoped></style>
