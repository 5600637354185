<template>
    <modal name="modal-delete" height="auto" @before-open="onBeforeOpen">
        <div class="tw-relative tw-bg-white dark:tw-bg-gray-800 tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-max-w-4xl md:tw-max-w-2xl lg:tw-max-w-3xl xl:tw-max-w-5xl tw-w-full">
            <div class="tw-bg-white dark:tw-bg-gray-800 tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
                <div class="sm:tw-flex sm:tw-items-start">
                    <div class="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-red-100 dark:tw-bg-gray-500 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                        <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 delete-icon" fill="none" viewBox="0 0 24 24" stroke="none" stroke-width="1.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                        </svg>
                    </div>
                    <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left tw-w-full">
                        <h3 class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 dark:tw-text-gray-400" id="modal-title">
                            Delete files
                        </h3>
                        <div class="tw-mt-2">
                            <p class="tw-text-sm tw-text-gray-500">Are you sure you want to delete this {{ item.type == "dir" ? "folder" : "file" }}?</p>
                            <p class="tw-flex tw-text-sm tw-text-gray-800 dark:tw-text-gray-400">
                                <svg v-if="item.type == 'dir'" xmlns="http://www.w3.org/2000/svg" class="folder-icon tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                                </svg>
                                <span class="tw-ml-1.5">{{ item.basename }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tw-bg-gray-50 dark:tw-bg-gray-800 dark:tw-border-t dark:tw-border-gray-700 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                <button @click="onDirDelete()" type="button" class="tw-cursor-pointer tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                    Yes, Delete!
                </button>

                <button @click="onClose()" type="button" class="tw-cursor-pointer tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                    Cancel
                </button>
                <div class="tw-m-auto tw-font-bold tw-text-red-500 tw-text-sm dark:tw-text-red-200 tw-text-center">This action cannot be undone.</div>
            </div>
        </div>
    </modal>
</template>

<script>
import {EventBus} from "@/main";

export default {
    name: "modal-delete",
    data() {
        return {
            item: {},
        };
    },
    methods: {
        onBeforeOpen({params}) {
            if (params.item) {
                this.item = params.item;
            } else {
                let item = params.getItems.find((item) => item._id === params.selectedItem);
                this.item = item;
            }
        },
        onClose() {
            this.$modal.hide("modal-delete");
        },
        onDirDelete() {
            EventBus.$emit("onDirDelete", this.item);
        },
    },
};
</script>

<style style="scss" scoped>
.delete-icon {
    stroke: #dc2626;
}
.folder-icon {
    stroke: #0ea5e9;
    fill: #0ea5e9;
}
</style>
