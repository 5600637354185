<template>
    <modal name="modal-replace" height="auto" :clickToClose="false" @before-open="onBeforeOpen">
        <header class="tw-flex tw-items-center tw-justify-between tw-p-4">
            <div class="tw-flex tw-flex-col">
                <span class="tw-font-semibold tw-text-gray-700 tw-text-lg tw-tracking-wide">Confirm Folder Replace</span>
                <span class="tw-text-gray-400 tw-font-medium tw-text-sm tw-tracking-wide">Folder already exist</span>
            </div>
            <svg @click="onClose" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x tw-rounded-full tw-p-2 tw-text-gray-600 tw-cursor-pointer hover:tw-text-brand-hover">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
        </header>
        <div class="tw-container tw-flex-grow tw-overflow-y-auto scroll-bar tw-px-4 tw-py-6">
            <div class="tw-text-gray-700 tw-break-normal tw-mt-2" v-if="folderNames.length > 0">
                This destination already contains folders named <span class="tw-text-black"> '{{ folderNames.join(", ") }}' </span>.
            </div>
            <div class="tw-text-gray-700 tw-break-normal tw-mt-2" v-if="fileNames.length > 0">
                This destination already contains files named <span class="tw-text-black">'{{ fileNames.join(", ") }}'</span>.
            </div>
            <div class="tw-mt-2 tw-text-gray-800 tw-font-medium">Do you want to replace?</div>
        </div>
        <footer class="tw-flex tw-items-center tw-justify-end tw-p-4 tw-border-t tw-border-0 tw-border-solid tw-border-gray-200 tw-bg-brand-area-color">
            <button @click="onSubmit()" class="tw-border-none tw-rounded tw-text-sm tw-h-full tw-py-2 tw-px-4 tw-flex tw-items-center tw-text-white tw-bg-brand tw-cursor-pointer hover:tw-bg-brand-hover tw-mr-4">
                Yes
            </button>
            <button @click="onClose" class="tw-border-none tw-rounded tw-text-sm tw-h-full tw-py-2 tw-px-4 tw-flex tw-items-center tw-text-white tw-bg-brand tw-cursor-pointer hover:tw-bg-brand-hover">
                No
            </button>
        </footer>
    </modal>
</template>

<script>
import {EventBus} from "@/main.js";

export default {
    name: "modal-replace",
    data() {
        return {
            folderNames: [],
            myFiles: [],
            fileNames: [],
        };
    },
    methods: {
        onClose() {
            this.$modal.hide("modal-replace");
        },
        onBeforeOpen({params}) {
            this.folderNames = params?.folderNames ?? [];
            this.fileNames = params?.fileNames ?? [];
            this.myFiles = params.files;
        },
        onSubmit() {
            EventBus.$emit("uploadFiles", this.myFiles);
        },
    },
};
</script>

<style style="scss" scoped></style>
