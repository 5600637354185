<template>
    <div class="tw-relative tw-flex-auto tw-flex tw-flex-col tw-overflow-hidden" @dragover.prevent="onDrag($event, true)">
        <div v-if="view == 'list' || searchQuery.length" class="tw-grid tw-grid-cols-12 tw-border-0 tw-border-b tw-border-gray-200 tw-text-xs tw-select-none">
            <div class="listView tw-py-1 tw-leading-6 tw-flex tw-items-center tw-pl-1 tw-col-span-7">
                Name
                <sort-icon :direction="sort.order == 'asc' ? 'down' : 'up'" v-show="sort.active && sort.column == 'basename'" />
            </div>
            <div v-if="!searchQuery.length" class="listView tw-py-1 tw-leading-6 tw-flex tw-items-center tw-pl-1 tw-col-span-2">
                Size
                <sort-icon :direction="sort.order == 'asc' ? 'down' : 'up'" v-show="sort.active && sort.column == 'file_size'" />
            </div>
            <div v-if="!searchQuery.length" class="listView tw-py-1 tw-leading-6 tw-flex tw-items-center tw-pl-1 tw-col-span-3">
                Date
                <sort-icon :direction="sort.order == 'asc' ? 'down' : 'up'" v-show="sort.active && sort.column == 'last_modified'" />
            </div>
            <div v-if="searchQuery.length" class="listView tw-py-1 tw-leading-6 tw-flex tw-items-center tw-pl-1 tw-col-span-5">
                Filepath
                <sort-icon :direction="sort.order == 'asc' ? 'down' : 'up'" v-show="sort.active && sort.column == 'path'" />
            </div>
        </div>

        <div :class="{'tw-resize-y': !fullScreen}" @contextmenu.self.prevent="onContextMenu($event, 'area')" class="tw-h-full tw-w-full tw-text-xs selector-area tw-overflow-auto scroll-bar tw-p-1 tw-z-0 tw-relative" ref="selectorArea" @drop="onDrop" @dragenter.prevent @dragover.prevent @click.self="onSelectItem({_id: null})">
            <div @dragleave.prevent.stop="onDrag($event, false)" class="tw-absolute tw-z-10 tw-inset-0" style="background-color: rgba(255, 255, 255, 0.5); backdrop-filter: blur(1px)" v-if="showMessageText || loadingState.fetching || loadingState.uploading">
                <!-- <error-message v-if="loadingState.fetching" message="Loading..." classes="tw-bg-blue-100 tw-mt-2 tw-border-blue-400 tw-text-blue-700" :loading="true" /> -->
                <error-message v-if="showMessageText && !loadingState.fetching && !loadingState.uploading && !searchMode && !readOnly" message="Drop your folders and files in this directory" classes="tw-bg-blue-100 tw-mt-2 tw-border-blue-400 tw-text-blue-700" />
                <error-message v-else-if="loadingState.uploading" message="Uploading..." classes="tw-bg-yellow-100 tw-mt-2 tw-border-yellow-400 tw-text-yellow-700" :loading="true" />
            </div>
            <!-- v-if="searchQuery.length" -->
            <template v-if="searchQuery.length">
                <div @click="onSelectItem(item)" @contextmenu.self.prevent="onContextMenu($event, item.type == 'dir' ? 'folder' : 'file', item)" @dblclick="openItem(item)" :class="{'selected-item': selectedItem === item._id}" class="tw-grid tw-grid-cols-1 tw-border tw-border-solid hover:tw-bg-gray-50 tw-border-transparent tw-my-0.5 tw-w-full tw-select-none" v-for="item in getItems" :key="item._id">
                    <div class="tw-grid tw-grid-cols-12 tw-items-center">
                        <div class="tw-flex tw-col-span-7 tw-items-center">
                            <svg v-if="item.type == 'dir'" xmlns="http://www.w3.org/2000/svg" class="folder-icon tw-h-5 tw-w-5 tw-text-gray-500 tw-fill-sky-500 tw-stroke-sky-500 dark:tw-fill-slate-500 dark:tw-stroke-slate-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                            </svg>
                            <span class="tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap">{{ item.basename }}</span>
                        </div>
                        <div class="tw-col-span-5 tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap">{{ item.gen_path }}</div>
                    </div>
                </div>
            </template>
            <template v-if="view == 'list' && !searchQuery.length">
                <div @click="onSelectItem(item)" @dblclick="openItem(item)" @contextmenu.prevent="onContextMenu($event, item.type == 'dir' ? 'folder' : 'file', item)" :class="{'selected-item': selectedItem === item._id}" class="tw-grid tw-grid-cols-1 tw-border tw-border-solid hover:tw-bg-gray-50 tw-border-transparent tw-my-0.5 tw-w-full tw-select-none" v-for="item in getItems" :key="item._id">
                    <div class="tw-grid tw-grid-cols-12 tw-items-center">
                        <div class="tw-flex tw-col-span-7 tw-items-center">
                            <svg v-if="item.type == 'dir'" xmlns="http://www.w3.org/2000/svg" class="folder-icon tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="tw-h-5 tw-w-5 tw-text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                            </svg>
                            <span class="tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap">{{ item.basename }}</span>
                        </div>
                        <div class="tw-col-span-2 tw-text-center">{{ item.file_size ? fileSize(item.file_size) : "N/A" }}</div>
                        <div class="tw-col-span-3 tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap">{{ datetimestring(item.last_modified) }}</div>
                    </div>
                </div>
            </template>
            <template v-if="view == 'grid' && !searchQuery.length">
                <div @dblclick="openItem(item)" @click="onSelectItem(item)" @contextmenu.prevent="onContextMenu($event, item.type == 'dir' ? 'folder' : 'file', item)" :class="{'selected-item': selectedItem === item._id}" class="tw-border tw-border-solid tw-border-transparent hover:tw-bg-gray-100 tw-m-1 tw-inline-flex tw-w-24 tw-h-20 md:tw-w-24 tw-text-center tw-justify-center tw-select-none" v-for="item in getItems" :key="item._id">
                    <div>
                        <div class="tw-relative">
                            <svg v-if="item.type == 'dir'" xmlns="http://www.w3.org/2000/svg" class="folder-icon tw-h-10 tw-w-10 md:tw-h-12 md:tw-w-12 tw-m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                            </svg>
                            <!-- <img class="tw-lazy tw-h-10 md:tw-h-12 tw-m-auto" v-else-if="(item.mime_type ?? '').startsWith('image')" :data-src="getImageUrl(adapter.value, item.path)" :alt="item.basename" /> -->
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="tw-h-10 tw-w-10 md:tw-h-12 md:tw-w-12 tw-m-auto tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                            </svg>
                            <!-- <div class="tw-absolute tw-hidden md:tw-block tw-top-1/2 tw-w-full tw-text-center tw-text-neutral-500" v-if="!(item.mime_type ?? '').startsWith('image') &amp;&amp; item.type != 'dir'">{{ ext(item.extension) }}</div> -->
                        </div>
                        <span class="tw-break-all">{{ titleShorten(item.basename) }}</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {EventBus} from "@/main";
import {fromEvent} from "file-selector";

const ErrorMessage = () => import("@/components/server-error-message");
const SortIcon = () => import("./SortIcon.vue");

export default {
    name: "Explorer",
    components: {SortIcon, ErrorMessage},
    data() {
        return {
            contextMenuOption: {
                area: [{name: "Refresh"}, {name: "New Folder"}],
                folder: [{name: "Open"}, {name: "Rename"}, {name: "Delete"}],
                file: [{name: "Rename"}, {name: "Delete"}],
            },
            showMessage: false,
        };
    },
    props: {
        view: {
            type: String,
            default: "grid",
        },
        searchQuery: {
            type: String,
            default: "",
        },
        sort: {
            type: Object,
            default: () => ({active: false, column: "", order: ""}),
        },
        fullScreen: {
            type: Boolean,
            default: false,
        },
        directoryContent: {
            type: Array,
            default: () => [],
        },
        getPath: {
            type: Array,
            default: () => [],
        },
        getItems: {
            type: Array,
            default: () => [],
        },
        selectedItem: {
            type: String,
            default: null,
        },
        loadingState: {
            type: Object,
            default: () => {},
        },
        searchMode: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showMessageText() {
            return this.showMessage || this.getItems.length <= 0;
        },
    },
    methods: {
        titleShorten(title) {
            return title.replace(/((?=([\w\W]{0,14}))([\w\W]{8,})([\w\W]{8,}))/, "$2..$4");
        },
        ext(item) {
            return item?.substring(0, 3);
        },
        datetimestring(timestamp) {
            return timestamp;
        },
        openItem(item) {
            EventBus.$emit("onOpenItem", item);
        },
        async onDrop(event) {
            event.preventDefault();
            const files = await fromEvent(event);
            this.showMessage = false;
            EventBus.$emit("onUploadFiles", files);
        },
        onSelectItem(item) {
            EventBus.$emit("onSelectItem", item);
        },
        onContextMenu(event, name, item = null) {
            event.preventDefault();
            EventBus.$emit("onContextMenu", {event, options: this.contextMenuOption[name], item});
        },
        fileSize(a, b, c, d, e) {
            return ((b = Math), (c = b.log), (d = 1024), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(0) + " " + (e ? "KMGTPEZY"[--e] + "iB" : "B");
        },
        onDrag(event, val) {
            event.preventDefault();
            this.showMessage = val;
        },
    },
};
</script>

<style lang="scss" scoped>
.listView {
    background-color: rgb(250, 250, 250);
    &:hover {
        background-color: rgb(245, 245, 245);
    }
}
.selector-area {
    min-height: 150px;
}
.folder-icon {
    stroke: #0ea5e9;
    fill: #0ea5e9;
}
.selected-item {
    border: 1px solid rgb(212, 212, 212) !important;
    background-color: rgb(245, 245, 245) !important;
}
</style>
